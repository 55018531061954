const InfoCard = ({ imgPath, title, body }) => {
    return (
        <div className="flex flex-col text-center sm:text-left sm:flex-row w-full gap-10 items-center justify-center">
            <img src={imgPath} className='max-w-24' alt="Info card one" width="100%" height="100%" />
            <div className='sm:mr-auto flex flex-col gap-2'>
                <div className="text-s2 uppercase font-semibold">
                    {title}
                </div>
                <div className="text-s1 text-justify">
                    {body}
                </div>
            </div>
        </div>
    );
}

export default InfoCard;