import CTAButton from "components/CTAButton";
import Footer from "components/Footer";
import NavBar from "components/NavBar";
import { Helmet } from "react-helmet";

const PageNotFound = () => {
    return (
        <main className="w-full p-12">
            <Helmet>
                <title>404 Page Not Found | Prima Trade</title>
            </Helmet>
            {/* <NavBar /> */}
            <div className="text-center my-32">
                <p className="text-base font-semibold text-brand-purple">404</p>
                <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
                <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn’t find the page you’re looking for.</p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                    <CTAButton btnText='Back to Home' href='/' size={2} hasArrow={true} opensNewTab={false} />
                </div>
            </div>
            {/* <Footer /> */}
        </main>
    );
}

export default PageNotFound;