import NavBar from "components/NavBar";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SocialShare from "./SocialShare";
import Footer from "components/Footer";
import LoadingSpinner from "components/LoadingSpinner";
import { Helmet } from "react-helmet";

const Post = () => {
  const [blogListSpinner, setBlogListSpinner] = useState(true);
  const [blogListError, setBlogListError] = useState({
    error: false,
    errorMessage: "Something went wrong, please try again later :(",
  });
  const [post, setPost] = useState({});
  const [allCategories, setAllCategories] = useState([]);
  const [categories, setCategories] = useState([]);

  const { slug } = useParams();

  const filterCategories = () => {
    if (post.categoryIds && allCategories.length > 0) {
      const filteredCategories = allCategories.filter((category) =>
        post.categoryIds.includes(category.id)
      );
      setCategories(filteredCategories);
    }
  };

  const fetchData = async () => {
    setBlogListSpinner(true);
    try {
      const [postResponse, categoriesResponse] = await Promise.all([
        fetch(`${process.env.REACT_APP_BLOGS_ENDPOINT}/posts/slugs/${slug}`, {
          method: "GET",
          headers: {
            "x-api-key": process.env.REACT_APP_BLOGS_API_KEY,
          },
        }),
        fetch(`${process.env.REACT_APP_BLOGS_ENDPOINT}/categories`, {
          method: "GET",
          headers: {
            "x-api-key": process.env.REACT_APP_BLOGS_API_KEY,
          },
        }),
      ]);

      const postData = await postResponse.json();
      const categoriesData = await categoriesResponse.json();

      if (!postData.body || !categoriesData.body) {
        setBlogListError({
          error: true,
          errorMessage: "Something went wrong, please try again later :(",
        });
      } else {
        setPost(postData.body);
        setAllCategories(categoriesData.body);
      }

      filterCategories();
    } catch (error) {
      console.error("Error:", error);
      setBlogListError({ ...blogListError, error: true });
    } finally {
      setBlogListSpinner(false);
    }
  };

  useEffect(() => {
    document.title = `Blog | ${slug}`;
    fetchData();
  }, []);

  useEffect(() => {
    filterCategories();
  }, [post, allCategories]);

  const renderText = (textData) => {
    if (!textData) return null;

    return textData.text.split("\n").map((text, idx) => (
      <span
        key={idx}
        style={{
          fontWeight: textData.decorations.find((dec) => dec.type === "BOLD")
            ? "bold"
            : "normal",
          fontStyle: textData.decorations.find((dec) => dec.type === "ITALIC")
            ? "italic"
            : "normal",
          textDecoration: textData.decorations.find(
            (dec) => dec.type === "UNDERLINE"
          )
            ? "underline"
            : "none",
          color:
            textData.decorations.find((dec) => dec.type === "COLOR")?.colorData
              ?.foreground || "inherit",
        }}
      >
        {textData.decorations.find((dec) => dec.type === "LINK") ? (
          <a
            href={
              textData.decorations.find((dec) => dec.type === "LINK").linkData
                .link.url
            }
            target="_blank"
            rel="noreferrer"
            className="text-red-500"
          >
            {text}
          </a>
        ) : (
          text
        )}
      </span>
    ));
  };

  const renderNode = (node, customFontSize) => {
    switch (node.type) {
      case "PARAGRAPH":
        return (
          <p
            key={node.id}
            className={`${customFontSize ? customFontSize : "text-xl font-normal"
              } mb-4`}
          >
            {node.nodes.map((childNode, idx) => renderText(childNode.textData))}
          </p>
        );
      case "BLOCKQUOTE":
        return (
          <blockquote
            key={node.id}
            className="pl-4 border-l-4 border-red-500 italic font-bold my-10 "
          >
            {node.nodes.map((childNode) =>
              renderNode(childNode, (customFontSize = "text-3xl"))
            )}
          </blockquote>
        );
      case "IMAGE":
        return (
          <div key={node.id} className="text-center mb-4">
            <img
              src={`https://static.wixstatic.com/media/${node.imageData.image.src.id}`}
              alt={node.imageData.altText}
              className="mx-auto"
              style={{ maxWidth: "100%", height: "auto" }}
            />
            {node.imageData.caption && (
              <p className="text-sm text-gray-500">{node.imageData.caption}</p>
            )}
          </div>
        );
      case "VIDEO":
        const videoSrc = node.videoData.video.src.url;
        const isVimeo = videoSrc.includes("vimeo.com");

        if (isVimeo) {
          const vimeoEmbedUrl = videoSrc.replace(
            "vimeo.com/",
            "player.vimeo.com/video/"
          );
          return (
            <div key={node.id} className="text-center mb-4 w-full">
              <iframe
                src={vimeoEmbedUrl}
                width="800"
                height="360"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="Embedded Vimeo Video"
                className="mx-auto"
              />
              {node.videoData.caption && (
                <p className="text-sm text-gray-500">
                  {node.videoData.caption}
                </p>
              )}
            </div>
          );
        }

        return (
          <div key={node.id} className="text-center mb-4">
            <video
              src={`https://static.wixstatic.com/media/${videoSrc}`}
              controls
              className="mx-auto"
              style={{ maxWidth: "100%", height: "auto" }}
            />
            {node.videoData.caption && (
              <p className="text-sm text-gray-500">{node.videoData.caption}</p>
            )}
          </div>
        );
      case "HEADING":
        const HeadingTag = `h${node.headingData.level}`;
        return (
          <HeadingTag key={node.id} className="my-4 font-bold text-xl">
            {node.nodes.map((childNode, idx) => renderText(childNode.textData))}
          </HeadingTag>
        );
      case "BULLETED_LIST":
        return (
          <ul key={node.id} className="list-disc pl-5 mb-4">
            {node.nodes.map((childNode) => renderNode(childNode))}
          </ul>
        );
      case "LIST_ITEM":
        return (
          <li key={node.id}>
            {node.nodes.map((childNode) => renderNode(childNode))}
          </li>
        );
      case "TABLE":
        return (
          <div key={node.id} className="overflow-x-auto">
            <table className="w-full divide-y divide-gray-200 mb-4 min-w-[500px]">
              <tbody>
                {node.nodes.map((rowNode) => (
                  <tr key={rowNode.id}>
                    {rowNode.nodes.map((cellNode) => (
                      <td key={cellNode.id} className="px-4 py-2 border w-52">
                        {cellNode.nodes.map((childNode) =>
                          renderNode(childNode)
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      default:
        return null;
    }
  };


  const SeoMetaData = ({ seoData }) => {
    return (
      <Helmet>
        {seoData.tags.map((tag, index) => {
          if (tag.type === 'title') {
            return <title key={index}>Blog | {tag.children}</title>;
          } else if (tag.type === 'meta') {
            return <meta key={index} {...tag.props} />;
          } else {
            return null;
          }
        })}

        {/* Handling Open Graph image tag with additional properties */}
        {seoData.tags.filter(tag => tag.type === 'meta' && tag.props.property === 'og:image').map((tag, index) => (
          <meta key={index} property="og:image" content={tag.props.content} />
        ))}

        {/* Additional settings like keywords can be added if needed */}
        {seoData.settings && seoData.settings.keywords && (
          <meta name="keywords" content={seoData.settings.keywords.map(keyword => keyword.term).join(', ')} />
        )}
      </Helmet>
    );
  };

  return (
    <main className="bg-red-50 bg-opacity-20 h-fit">
      {
        post.seoData && <SeoMetaData seoData={post.seoData} />
      }
      <NavBar />
      {blogListSpinner && <LoadingSpinner />}

      {(post.richContentString || post.title) && (
        <section className="max-w-[1000px] mx-auto p-10 bg-white mt-10 shadow-xl mb-10">
          {post.title && (
            <>
              <div className="mb-5 text-gray-500">
                <a href="/" className="underline">
                  Home
                </a>{" "}
                &nbsp;{">"}&nbsp;{" "}
                <a href="/blogs" className="underline">
                  Blog
                </a>{" "}
                &nbsp;{">"}&nbsp; {post.title}
              </div>
              <h1 className="text-5xl font-bold mb-4">{post.title}</h1>
            </>
          )}
          {post.minutesToRead && (
            <div className="flex flex-row gap-2 my-7">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="22"
                viewBox="0 0 21 22"
                fill="none"
              >
                <path
                  d="M11.2501 10.5588V4.8726C11.2501 4.45879 10.9142 4.12305 10.5001 4.12305C10.0861 4.12305 9.75012 4.45879 9.75012 4.8726V10.8691C9.75012 11.0682 9.82922 11.2585 9.96985 11.399L12.9699 14.3972C13.1163 14.5436 13.3077 14.6168 13.5001 14.6168C13.6925 14.6168 13.8839 14.5436 14.0304 14.3972C14.3234 14.1045 14.3234 13.6301 14.0304 13.3373L11.2501 10.5588Z"
                  fill="#D44A34"
                />
                <path
                  d="M10.5 0.375C4.71 0.375 0 5.08222 0 10.8688C0 16.6554 4.71 21.3626 10.5 21.3626C16.29 21.3626 21 16.6554 21 10.8688C21 5.08222 16.29 0.375 10.5 0.375ZM10.5 19.8635C5.53708 19.8635 1.5 15.8288 1.5 10.8688C1.5 5.90881 5.53708 1.87411 10.5 1.87411C15.4629 1.87411 19.5 5.90881 19.5 10.8688C19.5 15.8288 15.4629 19.8635 10.5 19.8635Z"
                  fill="#D44A34"
                />
              </svg>
              {post.minutesToRead} MIN READ
            </div>
          )}
          {post.richContentString &&
            JSON.parse(post.richContentString).nodes.map((node) =>
              renderNode(node)
            )}

          <div className="flex flex-row items-center justify-center gap-5 mt-10 py-5 border-t-2 border-b-2">
            {categories.map((category) => {
              return (
                <div
                  key={category.id}
                  className={`text-black border border-black w-fit rounded-3xl py-2 text-center font-bold text-sm text-nowrap px-5 uppercase`}
                >
                  {category.label}
                </div>
              );
            })}
          </div>
          <SocialShare />
        </section>
      )}
      <Footer />
    </main>
  );
};

export default Post;
