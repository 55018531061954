import { useState } from "react";
import Logo from "./Logo";
import { Twirl as Hamburger } from "hamburger-react";
import { links } from "constants/links";

const NavBar = ({ isSticky }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    return (
        <>
            {isSticky || isSticky == null ? (
                <div
                    className={`sticky bg-brand-darkRed px-2 sm:px-[12%] py-4 lg:py-5 w-full z-50 lg:justify-center top-0 left-0 flex
           flex-row items-center justify-between monitor:px-24`}
                    id="nav-bar"
                >
                    <div className="max-w-[200px] sm:max-w-none">
                        <Logo />
                    </div>

                    <div className=" lg:flex flex-row text-sm text-white gap-5 h-full items-center justify-center w-full mr-10 sm:mr-3 monitor:mr-0 hidden">
                        <a
                            href="/"
                            onClick={() => setIsSidebarOpen(false)}

                            className="text-nowrap hover:text-brand-orange uppercase text-s2"
                        >
                            Home
                        </a>

                        <a
                            href="/platform"
                            onClick={() => setIsSidebarOpen(false)}

                            className="text-nowrap  hover:text-brand-orange uppercase text-s2"
                        >
                            Platform
                        </a>
                        <a
                            href="/blogs"
                            onClick={() => setIsSidebarOpen(false)}

                            className="text-nowrap  hover:text-brand-orange uppercase text-s2"
                        >
                            BLOG
                        </a>

                        <a
                            onClick={() => setIsSidebarOpen(false)}
                            href="/contact"
                            className="text-nowrap hover:text-brand-orange uppercase text-s2">
                            Contact
                        </a>

                    </div>
                    <a href={links.login} target="_blank" rel="noreferrer" className="flex-row gap-2 underline text-white lg:flex items-center justify-center hidden">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                            <path d="M8.49962 9.62009C10.8991 9.62009 12.844 7.67635 12.844 5.2783C12.844 2.88026 10.8991 0.936523 8.49962 0.936523C6.10016 0.936523 4.15527 2.88026 4.15527 5.2783C4.15527 7.67635 6.10016 9.62009 8.49962 9.62009Z" fill="white" />
                            <path d="M6.53132 10.9414C2.92393 10.9414 0 13.8636 0 17.4689C0 17.7218 0.204373 17.926 0.457447 17.926H16.5426C16.7956 17.926 17 17.7218 17 17.4689C17 13.8636 14.0761 10.9414 10.4687 10.9414H6.53132Z" fill="white" />
                        </svg>
                        login
                    </a>
                    <div className="cursor-pointer text-white rounded-full overflow-hidden  transition-all duration-500 lg:hidden">
                        <Hamburger
                            color={"#fff"}
                            size={24}
                            toggled={isSidebarOpen}
                            toggle={setIsSidebarOpen}
                        />
                    </div>
                </div>
            ) : (
                <div
                    className={`${isSidebarOpen ? "bg-brand-darkRed opacity-95 lg:bg-none" : "bg-none"
                        }  absolute py-4 lg:top-6 md:w-full gap-5
             lg:py-5  z-50 lg:justify-center top-0 left-0 flex flex-row items-center justify-between px-4 w-full lg:px-[12%] mx-0
              transition-all duration-300 ease-in-out monitor:mx-auto monitor:max-w-[calc(1600px-100px)]`}
                    id="nav-bar"
                >
                    <div className="max-w-[200px] sm:max-w-none hover:scale-105 transition-all ease-in-out duration-200">
                        <Logo />
                    </div>

                    <div className=" lg:flex flex-row text-sm text-white gap-8 h-full items-center justify-center w-full monitor:mr-0 hidden">
                        <a
                            href="/"
                            onClick={() => setIsSidebarOpen(false)}
                            className="text-nowrap hover:text-brand-orange uppercase text-s2 transition-all duration-200 ease-in-out "
                        >
                            HOME
                        </a>

                        <a
                            href="/platform"
                            onClick={() => setIsSidebarOpen(false)}
                            className="text-nowrap hover:text-brand-orange uppercase text-s2 transition-all duration-200 ease-in-out"
                        >
                            Platform
                        </a>
                        <a
                            href="/blogs"
                            onClick={() => setIsSidebarOpen(false)}
                            className="text-nowrap hover:text-brand-orange uppercase text-s2 transition-all duration-200 ease-in-out"
                        >
                            Blog
                        </a>

                        <a
                            onClick={() => setIsSidebarOpen(false)}
                            href="/contact"
                            className="text-nowrap hover:text-brand-orange uppercase text-s2 transition-all duration-200 ease-in-out"
                        >
                            CONTACT
                        </a>
                    </div>
                    <a href={links.login} target="_blank" rel="noreferrer" className="flex-row gap-2 underline text-white lg:flex items-center justify-center hidden hover:scale-110 transition-all duration-200 ease-in-out">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="white">
                            <path d="M8.49962 9.62009C10.8991 9.62009 12.844 7.67635 12.844 5.2783C12.844 2.88026 10.8991 0.936523 8.49962 0.936523C6.10016 0.936523 4.15527 2.88026 4.15527 5.2783C4.15527 7.67635 6.10016 9.62009 8.49962 9.62009Z" />
                            <path d="M6.53132 10.9414C2.92393 10.9414 0 13.8636 0 17.4689C0 17.7218 0.204373 17.926 0.457447 17.926H16.5426C16.7956 17.926 17 17.7218 17 17.4689C17 13.8636 14.0761 10.9414 10.4687 10.9414H6.53132Z" />
                        </svg>
                        login
                    </a>
                    <div className="cursor-pointer text-white rounded-full overflow-hidden  transition-all duration-500 lg:hidden">
                        <Hamburger
                            size={24}
                            color={"#fff"}
                            toggled={isSidebarOpen}
                            toggle={setIsSidebarOpen}
                        />
                    </div>
                </div>
            )}

            <div
                className={`w-screen h-full pb-4 fixed top-[80px] lg:hidden bg-brand-darkRed opacity-95 z-50 transition-all duration-300 ease-in-out ${isSidebarOpen ? "right-0" : "-right-[110%]"
                    }`}
            >
                {isSidebarOpen && (
                    <div className="px-5 ">
                        <nav className="p-4 flex flex-col gap-6 text-white justify-center items-center mt-6">
                            <a
                                href="/"
                                onClick={() => setIsSidebarOpen(false)}

                                className="text-nowrap hover:text-brand-orange"
                            >
                                Home
                            </a>
                            <a
                                href="/platform"
                                onClick={() => setIsSidebarOpen(false)}

                                className="text-nowrap hover:text-brand-orange"
                            >
                                Platform
                            </a>
                            <a
                                href="/blogs"
                                onClick={() => setIsSidebarOpen(false)}

                                className="text-nowrap hover:text-brand-orange"
                            >
                                Blog
                            </a>
                            <a
                                href="/contact"
                                onClick={() => setIsSidebarOpen(false)}

                                className="text-nowrap hover:text-brand-orange"
                            >
                                Contact
                            </a>
                        </nav>
                        {/* <div className="flex flex-row gap-2 my-10 justify-center items-center">
                            <SocialLogo logoName="linkedin" isDark={true} />
                            <SocialLogo logoName="instagram" isDark={true} />
                        </div> */}

                    </div>
                )}
            </div>
        </>
    );
};

export default NavBar;
