const InfoGrid = () => {
  return (
    <div className="flex flex-col w-full h-fit">
      <div className="flex flex-col lg:flex-row lg:h-[900px]">
        <div className="w-full lg:w-1/2">
          <img
            src={require("assets/platform/info-grid/1.png")}
            alt="Container ship"
            width={"100%"}
            height={"100%"}
            loading="lazy"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="flex flex-col bg-brand-orange w-full lg:w-1/2 h-full px-[8%] py-10 justify-center  gap-10">
          <div className="text-[50px] text-white font-bold">
            Extend and automate
          </div>
          <div>
            <div className="text-s2 text-white font-bold">Benefits:</div>
            <ul className="text-white space-y-2 mt-2 font-normal">
              <li>- make existing programs bigger</li>
              <li>- pay suppliers earlier (at shipments)</li>
              <li>- reach all your suppliers (long tail)</li>
              <li>- automate your early approval processes</li>
            </ul>
          </div>
          <div>
            <div className="text-s2 text-white font-bold">How?</div>
            <ul className="text-white space-y-2 mt-2 font-normal">
              <li>- Use Prima to approve early payments</li>
              <li>- We send the approved payables file to the funder</li>
              <li>
                - No changes needed to the funder's platform and the funding
                documents
              </li>
            </ul>
          </div>
          <div>
            <div className="text-s2 text-white font-bold">What's involved?</div>
            <ul className="text-white space-y-2 mt-2 font-normal">
              <li>- Minimal investment or upfront costs</li>
              <li>- No IT project unless PO matching is needed</li>
              <li>- Just switch suppliers on and data flows</li>
              <li>
                - And we can save you, typically, 1% additionally on your spend
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* ROW 2 */}
      <div className="flex flex-col lg:flex-row-reverse lg:h-[900px]">
        <div className="w-full lg:w-1/2">
          <img
            src={require("assets/platform/info-grid/2.png")}
            alt="Container ship"
            width={"100%"}
            height={"100%"}
            loading="lazy"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="flex flex-col bg-brand-lightRed w-full lg:w-1/2 h-full px-[8%] py-10 justify-center  gap-10">
          <div className="text-[50px] text-black font-bold">
            Early payment brings savings
          </div>
          <div>
            <div className="text-s2 text-black font-bold">Benefits:</div>
            <ul className="text-black space-y-2 mt-2 font-normal">
              <li>- save up to 1% or more on landed costs</li>
              <li>- increase flexibilty of your supply chain</li>
              <li>- improve the strength of your suppliers</li>
            </ul>
          </div>
          <div>
            <div className="text-s2 text-black font-bold">How?</div>
            <ul className="text-black space-y-2 mt-2 font-normal">
              <li>- Just enable this function in the platform</li>
              <li>- Invoices approved (in full / in part) at shipment</li>
              <li>- NEarly payments flow for a discount</li>
              <li>- Risk is managed via the partial approval model</li>
            </ul>
          </div>
          <div>
            <div className="text-s2 text-black font-bold">What's involved?</div>
            <ul className="text-black space-y-2 mt-2 font-normal">
              <li>- Zero investment or upfront costs </li>
              <li>- No IT project unless PO matching is needed</li>
              <li>- Just switch suppliers on and data flows</li>
            </ul>
          </div>
        </div>
      </div>
      {/* END OF ROW 2 */}
      {/* ROW 3 */}
      <div className="flex flex-col lg:flex-row lg:h-[900px]">
        <div className="w-full lg:w-1/2">
          <img
            src={require("assets/platform/info-grid/1.png")}
            alt="Container ship"
            width={"100%"}
            height={"100%"}
            loading="lazy"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="flex flex-col bg-brand-darkGray w-full lg:w-1/2 h-full px-[8%] py-10 justify-center  gap-10">
          <div className="text-[50px] text-white font-bold">
            Add SCF to expand benefits{" "}
          </div>
          <div>
            <div className="text-s2 text-white font-bold">Benefits:</div>
            <ul className="text-white space-y-2 mt-2 font-normal">
              <li>- use finance to lengthen payment terms</li>
              <li>- trade payable accounting treatment</li>
              <li>- increase the savings</li>
              <li>- multiple funders and mix in with own funds ​</li>
            </ul>
          </div>
          <div>
            <div className="text-s2 text-white font-bold">How?</div>
            <ul className="text-white space-y-2 mt-2 font-normal">
              <li>- Bring in one or more funders (we can introduce)</li>
              <li>- Expand the program beyond your own liquidity</li>
              <li>- Platform manages all processes and payments</li>
            </ul>
          </div>
          <div>
            <div className="text-s2 text-white font-bold">What's involved?</div>
            <ul className="text-white space-y-2 mt-2 font-normal">
              <li>- Plug in RCF or trade lines from funders</li>
              <li>- Funders do not have to on-board the platform</li>
              <li>- Funders do not have to on-board suppliers</li>
              <li>- No geographical or supplier eligibility criteria</li>
            </ul>
          </div>
        </div>
      </div>
      {/* END OF ROW 2 */}
    </div>
  );
};

export default InfoGrid;
