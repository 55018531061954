import Footer from "components/Footer";
import NavBar from "components/NavBar";
import SubpageHero from "components/SubpageHero";
import { useEffect, useState } from "react";
import PrivacyContent from "./PrivacyContent";

const PrivacyPolicyPage = () => {
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            setIsSticky(scrollY > 40);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isSticky]);

    useEffect(() => {
        document.title = 'Platform';
    }, []);
    return (
        <div className="">
            <NavBar isSticky={isSticky} />
            <SubpageHero title="Privacy Policy" _renderBreadcrumbs={(
                <div className="text-s2 sm:text-s3"><a href="/">Legal</a> {">"} Privacy Policy</div>
            )} />
            <PrivacyContent />
            <Footer />
        </div>
    );
}

export default PrivacyPolicyPage;