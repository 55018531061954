import BlogList from "pages/BlogList";
import ContactPage from "pages/Contact";
import HomePage from "pages/HomePage";
import PageNotFound from "pages/PageNotFound";
import PlatformPage from "pages/PlatformPage";
import Post from "pages/Post";
import PrivacyPolicyPage from "pages/PrivacyPolicyPage";
import { Routes, Route } from "react-router-dom";

const RouteList = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/platform" element={<PlatformPage />} />
      <Route path="/legal/privacy" element={<PrivacyPolicyPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/blogs" element={<BlogList />} />
      <Route path="/post/:slug" element={<Post />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default RouteList;
