import CTAButton from "./CTAButton";

const InfoCardVertical = ({ img, title, body, btnText, btnLink, minutesToRead }) => {
    return (
        <div className="card bg-base-100 w-80 lg:w-96 shadow-lg h-full mx-auto">
            <figure className="bg-blue-500">
                <img
                    className="w-full h-60 object-cover object-center"
                    src={img}
                    alt="Shoes" />
            </figure>
            <div className="card-body text-center gap-8 px-5 lg:px-10">
                <div className="card-title font-bold text-s3 flex text-left w-fit mx-auto">{title}</div>
                <p>{body}</p>
                <div className="card-actions justify-center items-center gap-5 mt-3">
                    <CTAButton
                        hasArrow
                        btnText={btnText}
                        href={btnLink}
                        opensNewTab
                        size={1}
                    />
                    {minutesToRead &&
                        <div className="flex flex-row gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
                                <path d="M11.2501 10.5588V4.8726C11.2501 4.45879 10.9142 4.12305 10.5001 4.12305C10.0861 4.12305 9.75012 4.45879 9.75012 4.8726V10.8691C9.75012 11.0682 9.82922 11.2585 9.96985 11.399L12.9699 14.3972C13.1163 14.5436 13.3077 14.6168 13.5001 14.6168C13.6925 14.6168 13.8839 14.5436 14.0304 14.3972C14.3234 14.1045 14.3234 13.6301 14.0304 13.3373L11.2501 10.5588Z" fill="#D44A34" />
                                <path d="M10.5 0.375C4.71 0.375 0 5.08222 0 10.8688C0 16.6554 4.71 21.3626 10.5 21.3626C16.29 21.3626 21 16.6554 21 10.8688C21 5.08222 16.29 0.375 10.5 0.375ZM10.5 19.8635C5.53708 19.8635 1.5 15.8288 1.5 10.8688C1.5 5.90881 5.53708 1.87411 10.5 1.87411C15.4629 1.87411 19.5 5.90881 19.5 10.8688C19.5 15.8288 15.4629 19.8635 10.5 19.8635Z" fill="#D44A34" />
                            </svg>
                            {minutesToRead} MIN READ
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default InfoCardVertical;