const LoadingSpinner = () => {
    return (
        <div className="flex  h-screen mx-auto col-span-full pt-10 justify-center ">
            <div className="relative">
                <div className="h-[35vw] w-[35vw] rounded-full border-t-8 border-b-8 border-gray-200"></div>
                <div className="absolute top-0 left-0 h-[35vw] w-[35vw] rounded-full border-t-8 border-b-8 border-brand-orange animate-spin">
                </div>
            </div>
        </div>
    );
}

export default LoadingSpinner;