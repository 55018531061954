import CTAButton from "components/CTAButton";
import Footer from "components/Footer";
import NavBar from "components/NavBar";
import SubpageHero from "components/SubpageHero";
import { links } from "constants/links";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const ContactPage = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsSticky(scrollY > 40);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSticky]);


  return (
    <div>
      <Helmet>
        <title>Contact | Prima Trade</title>
        <meta name="description" content="Contact Prima Trade - Transform Your Financial Supply Chain" />

        <meta property="og:title"
          content="Contact Prima Trade - Transform Your Financial Supply Chain" />
        <meta property="og:description" content="Ready to optimize your financial supply chain? Book a call with Prima Trade today and discover how our Cash Against Data solution can transform your business, offering digitization, savings, and compliance." />

        <meta name="twitter:title"
          content="Contact Prima Trade - Transform Your Financial Supply Chain" />
        <meta name="twitter:description" content="Ready to optimize your financial supply chain? Book a call with Prima Trade today and discover how our Cash Against Data solution can transform your business, offering digitization, savings, and compliance." />
      </Helmet>
      <NavBar isSticky={isSticky} />
      <SubpageHero
        title="Contact"
        _renderBreadcrumbs={
          <div className="text-s2">
            <a href="/">Home</a> {">"} Contact
          </div>
        }
      />
      <div className="flex flex-col gap-10 lg:gap-5 lg:flex-row py-20 bg-brand-lightRed justify-between px-[7%] lg:px-[12%] items-center">
        <div>
          <div className="text-brand-lightGrey mb-10">
            Please use the form on the right to ask us any questions that you
            have.
          </div>
          <div className="font-bold">
            If you prefer, you can just hit the button to send us an email:
          </div>
          <div className="flex flex-col gap-10 my-10">
            <div className="flex flex-row gap-5 items-center">
              <img
                src={require("assets/platform/contact/email.png")}
                alt="email"
                className="w-14 h-auto"
                width={"100%"}
                height={"100%"}
              />
              <a
                href="https://mail.google.com/mail/?view=cm&fs=1&to=info@prima.trade"
                target="_blank"
                rel="noreferrer"
                className="text-s2 font-bold"
              >
                info@prima.trade{" "}
              </a>
            </div>
            <div className="flex flex-row gap-5 items-center">
              <img
                src={require("assets/platform/contact/phone.png")}
                alt="email"
                className="w-14 h-auto"
                width={"100%"}
                height={"100%"}
              />
              <a
                className="text-s2 font-bold"
                href={links.calendly}
                target="_blank"
                rel="noreferrer"
              >
                Book a call
              </a>
            </div>
            <div className="flex flex-row gap-5 items-center">
              <img
                src={require("assets/platform/contact/linkedin.png")}
                alt="email"
                className="w-14 h-auto"
                width={"100%"}
                height={"100%"}
              />
              <a
                className="text-s2 font-bold"
                href={links.linkedin}
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>
            </div>
          </div>
        </div>
        <form className="bg-white w-full h-full gap-3 px-5 py-8 rounded-3xl grid grid-cols-2 lg:w-1/2">
          <input
            type="text"
            placeholder="Full Name"
            className="col-span-2 border border-gray-500 border-opacity-20 px-4 py-4 text-sm uppercase font-normal"
          />
          <input
            type="text"
            placeholder="Company"
            className="col-span-2 sm:col-span-1 border border-gray-500 border-opacity-20 px-4 py-4 text-sm uppercase font-normal"
          />
          <input
            type="text"
            placeholder="Position"
            className="col-span-2 sm:col-span-1 border border-gray-500 border-opacity-20 px-4 py-4 text-sm uppercase font-normal"
          />
          <input
            type="email"
            placeholder="Email"
            className="col-span-2 sm:col-span-1 border border-gray-500 border-opacity-20 px-4 py-4 text-sm uppercase font-normal"
          />
          <input
            type="tel"
            placeholder="Phone"
            className="col-span-2 sm:col-span-1 border border-gray-500 border-opacity-20 px-4 py-4 text-sm uppercase font-normal"
          />
          <textarea
            placeholder="Message"
            className="col-span-2 w-full border border-gray-500 border-opacity-20 px-4 py-4 text-sm uppercase font-normal mb-5"
          />
          <div className="col-span-2 flex justify-center lg:justify-normal items-center">
            <CTAButton
              hasArrow
              btnText={"Submit"}
              // href={btnLink}
              opensNewTab={false}
              size={1}
            />
          </div>
        </form>
      </div>

      <Footer />
    </div>
  );
};

export default ContactPage;
