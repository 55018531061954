import CTAButton from "components/CTAButton";
import { links } from "constants/links";

const CTABanner = () => {
  return (
    <div className="w-auto m-7  lg:mx-auto lg:h-[680px]">
      <div className="bg-[url('assets/platform/cta-banner.png')] h-full w-full bg-cover bg-top flex justify-center items-center overflow-hidden rounded-[50px] lg:rounded-none py-10 px-5 lg:py-0 lg:px-0">
        <div className="flex flex-col items-center justify-center gap-10">
          <div className=" text-6xl lg:text-8xl text-white font-primary font-bold text-center ">
            Get <span className="text-primary-gradient">started </span> today
          </div>
          <div className="text-s1 sm:text-s2 text-white text-center">
            Contact us to discover how Prima Trade can transform <br /> your
            financial supply chain.
          </div>
          <div className="flex flex-col md:flex-row gap-5 md:gap-10 justify-center items-center">
            <CTAButton
              hasArrow={false}
              btnText={"Book a call"}
              href={links.calendly}
              opensNewTab={true}
              size={2}
            />
            <div className="flex flex-row gap-5">
              <a
                className="text-s1 text-white hover:text-brand-orange"
                href={links.calendly}
                target="_blank"
                rel="noreferrer"
              >
                Get in touch
              </a>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTABanner;
