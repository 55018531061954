import Footer from "components/Footer";
import NavBar from "components/NavBar";
import SubpageHero from "components/SubpageHero";
import AboutUs from "./AboutUs";
import Steps from "./Steps";
import InfoGrid from "./InfoGrid";
import CTABanner from "./CTABanner";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const PlatformPage = () => {
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            setIsSticky(scrollY > 40);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isSticky]);

    return (
        <div className="">
            <Helmet>
                <title>Platform | Prima Trade</title>
                <meta name="description" content="Discover Prima Trade's IT-light, scalable platform for supplier finance powered by data. Save on sourcing, achieve ICC compliance, and implement easily. From global MNCs to SMEs, our solution meets your needs." />

                <meta property="og:title"
                    content="Prima Trade Platform - Revolutionizing Supplier Finance" />
                <meta property="og:description" content="Discover Prima Trade's IT-light, scalable platform for supplier finance powered by data. Save on sourcing, achieve ICC compliance, and implement easily. From global MNCs to SMEs, our solution meets your needs." />

                <meta name="twitter:title"
                    content="Prima Trade - Cash Against Data for Financial Supply Chains" />
                <meta name="twitter:description" content="Discover Prima Trade's IT-light, scalable platform for supplier finance powered by data. Save on sourcing, achieve ICC compliance, and implement easily. From global MNCs to SMEs, our solution meets your needs." />
            </Helmet>
            <NavBar isSticky={isSticky} />
            <SubpageHero title="Platform" _renderBreadcrumbs={(
                <div className="text-s2 sm:text-s3"><a href="/">Home</a> {">"} Platform</div>
            )} />
            <AboutUs />
            <Steps />
            <InfoGrid />
            <CTABanner />
            <Footer />
        </div>
    );
}

export default PlatformPage;