import CTAButton from "components/CTAButton";
import { links } from "constants/links";

const AdvantageFeature = () => {
    return (
        <div className="flex flex-col min-[1200px]:flex-row gap-5 min-[1200px]:w-[87%] mx-auto py-16 sm:py-32 max-w-[1500px]">
            <div className="flex flex-col gap-7 max-w-[800px] px-5 mx-auto">
                <div className="text-[45px] md:text-s4 font-bold leading-none">
                    Why digitize your enterprise financial supply chain?
                </div>
                <div className="text-s1">
                    The financial supply chain covers purchase order matching, invoices, transport documents, compliance and ESG documents, supplier finance, payments and relating financing documents.
                    <br />
                    <br />
                    Our platform captures all financial supply chain transactions across all carriers, forwarders, geographies, products, ERPs, brands, categories and suppliers. Manage FX risks, cash flows, sourcing, forecasting and logistics  - top down rather than the bottom up.
                    <br />
                    <br />
                    Offer early payment at shipment to all your suppliers  and save an additional 1%+ on spend using "cash against data". If you already have an SCF program, keep your existing funders and platform, we can enhance your program without changing any of these.
                    <br />
                    <br />
                    Meet new ESG regulations, track the credentials of products and services that you source; collect and evidence ESG characteristics at product or SKU level.
                    <br />
                    <br />
                </div>
                <div className="mx-auto w-[190px] min-[1200px]:mx-0 min-[1200px]:w-auto">
                    <CTAButton
                        hasArrow={false}
                        btnText={"Book a call"}
                        href={links.calendly}
                        opensNewTab={true}
                        size={2}
                    />
                </div>
            </div>
            <img src={require('assets/home/advantage-feature.png')} alt="Factory worker" width="100%" height="100%" className="object-cover h-full my-auto max-h-[500px] min-[1200px]:max-h-[640px] rounded-3xl w-[90%] min-[1200px]:w-auto mx-auto" />
        </div>
    );
}

export default AdvantageFeature;