const ClientList = () => {
    const data = [
        {
            img: require('assets/home/clients/1.png'),
            alt: 'Client Logo 1'
        },
        {
            img: require('assets/home/clients/2.png'),
            alt: 'Client Logo 2'
        },
        {
            img: require('assets/home/clients/3.png'),
            alt: 'Client Logo 3'
        },
        {
            img: require('assets/home/clients/4.png'),
            alt: 'Client Logo 4'
        },
        {
            img: require('assets/home/clients/5.png'),
            alt: 'Client Logo 5'
        },
    ]
    return (
        <div className="flex flex-col w-full items-center justify-center gap-10 mb-20">
            <div className="uppercase w-full text-center text-s2 opacity-70">
                our clients include
            </div>
            <div className="grid grid-cols-2 gap-9 md:flex md:flex-row md:gap-16 lg:gap-24 items-center justify-center h-fit">

                {
                    data.map((client) => {
                        return (
                            <div className='opacity-70 last:col-span-2 mx-auto h-auto w-32 md:w-auto'>
                                <img src={client.img} alt={client.alt}
                                    height="100%"
                                    width="100%"
                                    className="h-full" />
                            </div>

                        )
                    })
                }
            </div>
        </div>
    );
}

export default ClientList;