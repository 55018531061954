import InfoCard from "components/InfoCard";

const ImplementationFeature = () => {
    const data = [
        {
            imgPath: require('assets/home/implementation_feature/1.png'),
            title: "IT-Light Solution",
            body: "Our platform generates positive returns from day one and requires minimal IT involvement to get started."
        },
        {
            imgPath: require('assets/home/implementation_feature/2.png'),
            title: "Scalability",
            body: "From global MNCs to SMEs, our solution scales to meet your needs."
        },
        {
            imgPath: require('assets/home/implementation_feature/3.png'),
            title: "ICC Compliance",
            body: "Achieve full compliance with the ICC's digital trade standards."
        },
    ]
    return (
        <div className="flex flex-col gap-10 py-20 px-[5%] lg:px-28 text-black justify-center items-center bg-brand-lightRed">
            <div className="flex flex-col lg:flex-row gap-20  w-full max-w-[1500px] mx-auto">
                <div className="flex flex-col items-center justify-start gap-10 w-fit">
                    {
                        data.map((card) => {
                            return (
                                <InfoCard
                                    imgPath={card.imgPath}
                                    title={card.title}
                                    body={card.body}
                                />
                            )
                        })
                    }

                </div>
                <div className="w-full flex justify-center items-center mx-auto text-center sm:text-left">
                    <div className="max-w-[500px] min-h-[450px] w-auto sm:mx-0 px-[5%] sm:px-14 py-10 justify-center items-start flex h-full my-auto cursor-pointer bg-white  flex-col gap-16 rounded-2xl shadow-md">
                        <div className="text-black font-bold text-[45px] w-full sm:text-s4 leading-none">
                            Easy to <br /> Implement
                        </div>
                        <div>
                            Our PrimaTrade platform is powered by supplier data and is self-contained. This means we can go live without an initial IT project. Nominate a few suppliers and they can be live within a week. Test us out without obligation – and go from there.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ImplementationFeature;