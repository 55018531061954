import CTAButton from "components/CTAButton";

const PrivacyContent = () => {
    return (
        <section className="max-w-[1000px] mx-auto p-10 bg-white mt-10 shadow-xl mb-10">
            <h1 className="md:text-s4 text-s3 font-bold">Who are we?</h1>
            <br />
            <p className="text-s2">
                We are the PrimaTrade group of companies. The parent of the group is registered in the UK under company number: 14132357. As a group, we value your privacy and the safety of your personal information is very important to us, we are committed to protecting your data.
                <br />
                <br />
                This privacy policy explains what we are doing with your personal information and what standards you can expect from us.
                <br />
                <br />
                By using our service, you agree to the collection and usage of information in accordance with this policy.
                <br />
                <br />
                For the purpose of the Data Protection Act (GDPR) 2018, the registered data controller is PrimaTrade Services Limited, whose registered office is at 88 The Mount, Guildford, Surrey, United Kingdom, GU2 4JB. PrimaTrade Services Limited is registered on the Data protection register.
            </p>
            <br />
            <h1 className="md:text-s4 text-s3 font-bold leading-tight">What information might we collect?</h1>
            <br />
            <ul className="list-disc list-inside text-s2">
                <li>Identity</li>
                <li>Full Name</li>
                <li>Date of Birth</li>
                <li>Nationality</li>
                <li>ID document details</li>
                <li>Financial information</li>
                <li>Bank details</li>
                <li>Contact</li>
                <li>Home and/or work address</li>
                <li>Email Address</li>
                <li>Telephone Number</li>
                <li>Device</li>
                <li>Internet Protocol (IP) Address used</li>
                <li>Username</li>
                <li>Password</li>
                <li>History of interactions</li>
            </ul>
            <br />


            <h1 className="md:text-s4 text-s3 font-bold">How do we collect your data?</h1>
            <br />
            <p className="text-s2">
                Your interactions provide us with the data we collect. We collect data and process data when:
                <br />
                <ul className="list-disc list-inside text-s2">
                    <li> You visit our website and interact with us via the site</li>
                    <li>Have face to face meetings with our employees</li>
                    <li>Telephone or video or online calls</li>
                    <li>Register online</li>
                    <li>Use or view our website via your browser’s cookies</li>
                    <li>Provide information and documentation to us as part of our onboarding process</li>
                </ul>
                <br />
                We may also receive your data indirectly from relevant sources such as software tools to measure and collect session information, including page response times, length of visits to certain pages, page interaction information, and methods used to browse away from the page.                <br />
            </p>
            <br />

            <h1 className="md:text-s4 text-s3 font-bold">How will we use your data?</h1>
            <br />
            <p className="text-s2">
                We collect your data so that we can:
                <br />
                <ul className="list-disc list-inside text-s2">
                    <li>Proactively monitor your account.</li>
                    <li>Email you with other products and services we think you might like.</li>
                    <li>Use this information to make commercial decisions about your account and our actual or potential business with you and with other companies that you might do business with.</li>
                </ul>
                <br />
                When we process your account, we may send your data to, and also use the resulting information from, credit reference agencies to prevent fraudulent activity.
                <br />
            </p>
            <br />

            <h1 className="md:text-s4 text-s3 font-bold">When would we share your data?</h1>
            <br />
            <p className="text-s2">
                <br />
                <ul className="list-disc list-inside text-s2">
                    <li>Verification and Compliance Checks</li>
                    <li>Hosting and IT services</li>
                    <li>Payment Processing</li>
                </ul>
                <br />
            </p>
            <br />


            <h1 className="md:text-s4 text-s3 font-bold">How do we store your data?</h1>
            <br />
            <p className="text-s2 text-pretty">
                We securely store data within an encrypted Azure database located in our UK South data centre. The database server is located behind a firewall to prevent unauthorised access and user access is only available with a valid Azure Active Directory account allocated by an Azure Admin employed by PrimaDollar Operations Limited. We will keep your personal and company information securely stored for 5 years after the business relationship has ended. Once this time period has expired, we will delete your data by removing it from all our systems.
            </p>
            <br />

            <h1 className="md:text-s4 text-s3 font-bold">Marketing</h1>
            <br />
            <p className="text-s2 text-pretty">
                We would like to send you information about products and services of ours that we think you might like, as well as those of our partner companies. If you have agreed to receive marketing, you may always opt out at a later date. You have the right at any time to stop PrimaDollar from contacting you for marketing purposes. If you no longer wish to be contacted for marketing purposes, please contact us so we can update your preferences.
            </p>
            <br />

            <h1 className="md:text-s4 text-s3 font-bold">What are your data protection rights?</h1>
            <br />
            <p className="text-s2">
                The below are your key data protection rights:
                <br />
                <ul className="list-disc list-inside text-s2">
                    <li>The right to access – You have the right to ask for a copy of your personal data. We may charge you a small administration fee for this service.</li>
                    <li>The right to rectification – You can request that we correct any information you believe is inaccurate.</li>
                    <li>The right to erasure – ability to request that we erase your personal data, under certain conditions this can be agreed.</li>
                </ul>
                <br />
                There are several other rights that are available to you in the UK under the Data Protection Act (GDPR) 2018.
                <br />
            </p>
            <br />
            <br />
            <CTAButton size={1} hasArrow btnText={"Contact Us"} href={"/contact"} opensNewTab  />

        </section>
    );
}

export default PrivacyContent;