const PersonCard = ({ img, name, title, _renderBodyText }) => {
    return (
        <div className="bg-white rounded-3xl gap-2 flex flex-col p-10 items-center justify-center lg:justify-normal lg:items-normal lg:w-[33%]">
            <img src={img} alt="Tim" width="100%" height="100%"
                className='max-w-[170px] h-auto mb-5' />
            <div className='font-bold text-brand-orange text-s3 text-center lg:text-left '>{name}</div>
            <div className='font-bold mb-5 text-s2 text-center lg:text-left '>{title}</div>
            {_renderBodyText}
        </div>
    );
}

export default PersonCard;