import InfoCardVertical from "components/InfoCardVertical";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
const Blog = () => {
    const data = [
        {
            img: require("assets/home/blog/1.png"),
            title: "Digitization of trade",
            body: "Digitization can re-energize trade finance for manufacturing supply chains. Here's why 'cash against data' is so important.",
            btnText: "See more",
            minutesToRead: 4
        },
        {
            img: require("assets/home/blog/2.png"),
            title: "Cash against data",
            body: "Cash against data is a simple, leading method for CFOs and Treasurers to deliver trade finance for supply chains of manufacturing goods.",
            btnText: "See more",
            minutesToRead: 2
        },
        {
            img: require("assets/home/blog/3.png"),
            title: "Prima wins the ICC trade award",
            body: "Prima wins the ICC digital trade award for its cash against data solution - digitizing the financial supply chain.",
            btnText: "See more",
            minutesToRead: 2
        }
    ]
    const breakpoints = {
        320: {
            slidesPerView: 1,
            spaceBetween: 4,
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 4,
        },
        1260: {
            slidesPerView: 3,
            spaceBetween: 2,
        },
    }
    return (
        <div className=" py-20 bg-brand-lightRed w-full">
            <div className="pl-5 w-full lg:pl-[5%] gap-16">
                <div className="font-bold text-s4 mb-10">
                    Blog
                </div>
                <div className=" max-w-[1400px] mb-10">

                    <Swiper
                        spaceBetween={0}
                        // slidesPerView={3}
                        breakpoints={breakpoints}
                        loop={true}
                        className="pb-1">
                        {
                            data.map((elem) => {
                                return (
                                    <SwiperSlide
                                        key={elem.id}>
                                        <InfoCardVertical
                                            img={elem.img}
                                            title={elem.title}
                                            body={elem.body}
                                            btnText={elem.btnText}
                                            minutesToRead={elem.minutesToRead}
                                            postId={elem.id}
                                            btnLink={"/blogs"} />
                                    </SwiperSlide>
                                )
                            })
                        }

                    </Swiper>
                </div>
                <a href="/blogs"
                    target="_blank"
                    rel="noreferrer"
                    className={`bg-transparent text-s1 w-fit text-center rounded-full text-black flex flex-row gap-4 justify-center items-center
                                px-10 pb-5 pt-4 border border-black mx-auto`}>
                    <div className="text-nowrap font-bold">See more articles</div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 29 16" fill="#000">
                        <path d="M28.7071 8.70711C29.0976 8.31658 29.0976 7.68342 28.7071 7.29289L22.3431 0.928932C21.9526 0.538408 21.3195 0.538408 20.9289 0.928932C20.5384 1.31946 20.5384 1.95262 20.9289 2.34315L26.5858 8L20.9289 13.6569C20.5384 14.0474 20.5384 14.6805 20.9289 15.0711C21.3195 15.4616 21.9526 15.4616 22.3431 15.0711L28.7071 8.70711ZM0 9H28V7H0V9Z" fill="black" />
                    </svg>
                </a>
            </div>

        </div>
    );
}

export default Blog;