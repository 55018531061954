const SubpageHero = ({title, _renderBreadcrumbs}) => {
    return (
        <div className="bg-[url('assets/subpage-hero-bg.png')] w-full bg-cover h-[60vh] flex flex-col justify-center px-[12%] top-0 gap-10">

            <div className="text-white">
                <div className="text-s4 sm:text-s5 font-bold">{title}</div>
                {_renderBreadcrumbs}
            </div>
        </div>
    );
}

export default SubpageHero;