import { links } from "constants/links";
import Logo from "./Logo";

const Footer = () => {
  return (
    <div className="h-fit w-full flex flex-col">
      <div className="py-7 lg:border sm:border-t-gray-300 flex-col gap-10 lg:flex-row lg:gap-0 justify-around flex items-center px-[7%]">
        <Logo height={44} width={"auto"} />
        <div className="flex flex-row lg:justify-end justify-center items-center w-full ">
          <div className="flex-row space-x-10 hidden lg:flex mx-auto">
            <a href="/" className="font-bold text-s2 hover:text-brand-orange hover:scale-105 transition-all ease-in-out duration-200">
              home
            </a>
            <a href="/platform" className="font-bold text-s2 hover:text-brand-orange hover:scale-105 transition-all ease-in-out duration-200">
              platform
            </a>
            <a href="/blogs" className="font-bold text-s2 hover:text-brand-orange hover:scale-105 transition-all ease-in-out duration-200">
              blog
            </a>
            <a href="/contact" className="font-bold text-s2 hover:text-brand-orange hover:scale-105 transition-all ease-in-out duration-200">
              contact
            </a>
          </div>
          <div className="lg:ml-10 mr-5">
            <a
              href={links.linkedin}
              target="_blank"
              rel="noreferrer"
              className="h-fit w-fit my-auto"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                className=" hover:scale-110 transition-all ease-in-out duration-200"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="59"
                  height="59"
                  rx="29.5"
                  stroke="#380C09"
                />
                <path
                  d="M40.1581 19.0021H20.9692C20.7219 18.9905 20.4747 19.0278 20.2418 19.1118C20.0089 19.1958 19.7949 19.3249 19.6119 19.4917C19.429 19.6585 19.2808 19.8597 19.1758 20.0838C19.0708 20.3079 19.0111 20.5505 19 20.7977V40.0184C19.0145 40.5357 19.2266 41.028 19.5928 41.3939C19.959 41.7599 20.4515 41.9719 20.9692 41.9864H40.1581C40.6613 41.964 41.1358 41.7454 41.4797 41.3775C41.8237 41.0096 42.0097 40.5217 41.9979 40.0184V20.7977C42.0021 20.5571 41.9569 20.3182 41.8654 20.0956C41.7738 19.8731 41.6377 19.6715 41.4654 19.5034C41.2932 19.3352 41.0883 19.2041 40.8635 19.1179C40.6387 19.0317 40.3987 18.9923 40.1581 19.0021ZM26.1868 38.1653H22.8378V27.9229H26.1868V38.1653ZM24.5914 26.3714C24.3577 26.3796 24.1248 26.3393 23.9074 26.2532C23.69 26.1671 23.4928 26.037 23.3281 25.8711C23.1634 25.7051 23.0349 25.507 22.9505 25.289C22.8661 25.071 22.8277 24.838 22.8378 24.6045C22.8274 24.3685 22.8666 24.1329 22.9527 23.9129C23.0388 23.6929 23.17 23.4933 23.3378 23.3269C23.5056 23.1606 23.7064 23.0311 23.9272 22.9468C24.148 22.8625 24.3841 22.8253 24.6201 22.8376C24.8538 22.8294 25.0867 22.8697 25.3041 22.9558C25.5215 23.0419 25.7187 23.172 25.8834 23.3379C26.048 23.5039 26.1766 23.702 26.261 23.92C26.3454 24.1379 26.3837 24.371 26.3737 24.6045C26.384 24.8405 26.3449 25.0761 26.2588 25.2961C26.1727 25.5161 26.0415 25.7157 25.8737 25.882C25.7059 26.0484 25.5051 26.1779 25.2843 26.2622C25.0634 26.3464 24.8274 26.3836 24.5914 26.3714ZM38.1745 38.1653H34.8111V32.5628C34.8111 31.2269 34.3367 30.3075 33.1437 30.3075C32.7732 30.3107 32.4125 30.4283 32.1115 30.6444C31.8104 30.8604 31.5836 31.1643 31.462 31.5142C31.3686 31.7765 31.3295 32.055 31.347 32.333V38.1653H28.041V27.9229H31.347V29.3594C31.6409 28.8197 32.0783 28.3718 32.611 28.065C33.1437 27.7583 33.7508 27.6047 34.3655 27.6212C36.5359 27.6212 38.1745 29.0577 38.1745 32.1175V38.1653Z"
                  fill="#380C09"
                />
              </svg>
            </a>
          </div>
          <a
            href={links.login}
            target="_blank"
            rel="noreferrer"
            className="flex-row gap-3 underline text-white flex items-center justify-center hover:scale-110 transition-all ease-in-out duration-200 bg-primary-gradient px-5 sm:px-8 py-4 rounded-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
            >
              <path
                d="M8.49962 9.62009C10.8991 9.62009 12.844 7.67635 12.844 5.2783C12.844 2.88026 10.8991 0.936523 8.49962 0.936523C6.10016 0.936523 4.15527 2.88026 4.15527 5.2783C4.15527 7.67635 6.10016 9.62009 8.49962 9.62009Z"
                fill="white"
              />
              <path
                d="M6.53132 10.9414C2.92393 10.9414 0 13.8636 0 17.4689C0 17.7218 0.204373 17.926 0.457447 17.926H16.5426C16.7956 17.926 17 17.7218 17 17.4689C17 13.8636 14.0761 10.9414 10.4687 10.9414H6.53132Z"
                fill="white"
              />
            </svg>
            <div className="h-fit text-s2 hidden sm:block">login</div>
          </a>
        </div>
      </div>
      <div className="py-10 lg:border lg:border-t-gray-300  flex flex-col justify-center items-center lg:items-start lg:flex-row lg:justify-between px-[8%]">
        <div className="opacity-80">
          ©{new Date().getFullYear()} by PrimaTrade Systems Limited
        </div>
        <a href="/legal/privacy" className="opacity-80 underline hover:text-brand-orange transition-all ease-in-out duration-200">
          Privacy Policy
        </a>
      </div>
    </div>
  );
};

export default Footer;
