import NavBar from "components/NavBar";
import { useEffect, useState } from "react";
import Hero from "./Hero";
import VideoFeature from "./VideoFeature";
import ClientList from "./ClientList";
import ImplementationFeature from "./ImplementationFeature";
import CTAFeature from "./CTAFeature";
import CTABanner from "./CTABanner";
import AdvantageFeature from "./AdvantageFeature";
import Team from "./Team";
import Blog from "./Blog";
import Footer from "components/Footer";


const HomePage = () => {
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            setIsSticky(scrollY > 40);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isSticky]);

    useEffect(() => {
        document.title = 'Home';
    }, []);
    return (
        <div className="">
            <NavBar isSticky={isSticky} />
            <Hero />
            <VideoFeature />
            <ClientList />
            <ImplementationFeature />
            <CTAFeature />
            <CTABanner />
            <AdvantageFeature />
        <Team />
            <div className="bg-brand-lightRed">
                <CTABanner />
            </div>
            <Blog />
            <Footer />
        </div>
    );
}

export default HomePage;