const SocialShare = () => {
    const url = window.location.href;

    return (
        <div className='flex flex-col items-center justify-center gap-5 pt-5'>
            <div className="uppercase font-light">
                Share this article
            </div>
            <div className="flex flex-row gap-5 items-center justify-center">
                <a href={`http://www.twitter.com/share?url=${url}`} target="_blank" rel="noopener noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9455 23L10.396 15.0901L3.44886 23H0.509766L9.09209 13.2311L0.509766 1H8.05571L13.286 8.45502L19.8393 1H22.7784L14.5943 10.3165L23.4914 23H15.9455ZM19.2185 20.77H17.2398L4.71811 3.23H6.6971L11.7121 10.2532L12.5793 11.4719L19.2185 20.77Z" fill="#D44A34" />
                    </svg>
                </a>
                <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}`} target="_blank" rel="noopener noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path d="M22.4236 0H1.97207C0.992383 0 0.200195 0.773438 0.200195 1.72969V22.2656C0.200195 23.2219 0.992383 24 1.97207 24H22.4236C23.4033 24 24.2002 23.2219 24.2002 22.2703V1.72969C24.2002 0.773438 23.4033 0 22.4236 0ZM7.32051 20.4516H3.75801V8.99531H7.32051V20.4516ZM5.53926 7.43438C4.39551 7.43438 3.47207 6.51094 3.47207 5.37187C3.47207 4.23281 4.39551 3.30937 5.53926 3.30937C6.67832 3.30937 7.60176 4.23281 7.60176 5.37187C7.60176 6.50625 6.67832 7.43438 5.53926 7.43438ZM20.6518 20.4516H17.0939V14.8828C17.0939 13.5562 17.0705 11.8453 15.2424 11.8453C13.3908 11.8453 13.1096 13.2937 13.1096 14.7891V20.4516H9.55645V8.99531H12.9689V10.5609H13.0158C13.4893 9.66094 14.6518 8.70938 16.3814 8.70938C19.9861 8.70938 20.6518 11.0813 20.6518 14.1656V20.4516Z" fill="#D44A34" />
                    </svg>
                </a>
                <a href={`http://www.facebook.com/sharer/sharer.php?u=${url}&t=Check out this interesting article.`} target="_blank" rel="noopener noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <g clip-path="url(#clip0_693_1403)">
                            <path d="M24.3999 12C24.3999 5.37258 19.0273 0 12.3999 0C5.77248 0 0.399902 5.37258 0.399902 12C0.399902 17.9895 4.78811 22.954 10.5249 23.8542V15.4688H7.47803V12H10.5249V9.35625C10.5249 6.34875 12.3165 4.6875 15.0575 4.6875C16.37 4.6875 17.7437 4.92188 17.7437 4.92188V7.875H16.2305C14.7399 7.875 14.2749 8.80008 14.2749 9.75V12H17.603L17.071 15.4688H14.2749V23.8542C20.0117 22.954 24.3999 17.9895 24.3999 12Z" fill="#D44A34" />
                        </g>
                        <defs>
                            <clipPath id="clip0_693_1403">
                                <rect width="24" height="24" fill="white" transform="translate(0.399902)" />
                            </clipPath>
                        </defs>
                    </svg>
                </a>
            </div>
        </div>
    );
}

export default SocialShare;