import Footer from "components/Footer";
import InfoCardVertical from "components/InfoCardVertical";
import LoadingSpinner from "components/LoadingSpinner";
import NavBar from "components/NavBar";
import SubpageHero from "components/SubpageHero";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const BlogList = () => {
    const [blogs, setBlogs] = useState([]);
    const [filteredBlogs, setFilteredBlogs] = useState([]);
    const [isSticky, setIsSticky] = useState(false);
    const [categories, setCategories] = useState([]);
    const [blogListSpinner, setBlogListSpinner] = useState(true);
    const [blogListError, setBlogListError] = useState({
        error: false,
        errorMessage: 'Something went wrong, please try again later :('
    });


    async function fetchAllBlogs() {
        setBlogListSpinner(true);
        await fetch(`${process.env.REACT_APP_BLOGS_ENDPOINT}/posts`, {
            method: 'GET',
            headers: {
                'x-api-key': process.env.REACT_APP_BLOGS_API_KEY
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.body.length === 0) {
                    setBlogListError({
                        error: true,
                        errorMessage: 'There are no blog posts to display at the moment :('
                    });
                } else {
                    setBlogs(data.body)
                    setFilteredBlogs(data.body)
                }
            })
            .catch(error => {
                console.error('Error:', error)
                setBlogListError({ ...blogListError, error: true });

            });
        setBlogListSpinner(false);

    }
    async function fetchAllCategories() {
        try {
            await fetch(`${process.env.REACT_APP_BLOGS_ENDPOINT}/categories`, {
                method: 'GET',
                headers: {
                    'x-api-key': process.env.REACT_APP_BLOGS_API_KEY
                }
            })
                .then(response => response.json())
                .then(data => {
                    // Add an isActive property to each category, false by default so that All Posts is always initially active
                    setCategories(data.body.map(category => ({ ...category, isActive: false })));
                })
                .catch(error => console.error('Error:', error));
        } catch (e) {
            console.error(e);
        }
    }

    const changeCategory = (categoryId) => {
        let newCategories
        if (!categoryId) {
            setFilteredBlogs(blogs)
            newCategories = categories.map((category) => {
                return { ...category, isActive: false }
            });

        } else {
            newCategories = categories.map((category) => {
                if (categoryId && category.id === categoryId) {
                    //Filter the blog based on the category ID chosen
                    let tempFilteredBlogs = blogs.filter(blog => blog.categoryIds.includes(categoryId));
                    // Handle empty category
                    if (tempFilteredBlogs.length === 0) {
                        setBlogListError({
                            error: true,
                            errorMessage: 'There are no blog posts to display at the moment :('
                        });

                    } else {
                        setFilteredBlogs(tempFilteredBlogs)
                    }

                    return { ...category, isActive: true }
                } else {
                    return { ...category, isActive: false }
                }
            })
        }
        setCategories(newCategories)
    }


    useEffect(() => {
        fetchAllBlogs()
        fetchAllCategories()
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            setIsSticky(scrollY > 40);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isSticky]);

    const filterBtnActiveClasses = "bg-brand-orange border-brand-orange text-white border border-brand-orange";
    const filterBtnInactiveClasses = "text-black border border-transparent hover:border-brand-orange hover:text-brand-orange";

    return (
        <div>
            <Helmet>
                <title>Blog | Prima Trade</title>
                <meta name="description" content="Prima Trade Blog - Insights on Digital Trade Finance" />

                <meta property="og:title"
                    content="Prima Trade Blog - Insights on Digital Trade Finance" />
                <meta property="og:description" content="Explore expert articles on trade finance digitization, cash against data strategies, and industry recognition. Stay informed about transforming financial supply chains and Prima Trade's latest achievements." />

                <meta name="twitter:title"
                    content="Prima Trade Blog - Insights on Digital Trade Finance" />
                <meta name="twitter:description" content="Explore expert articles on trade finance digitization, cash against data strategies, and industry recognition. Stay informed about transforming financial supply chains and Prima Trade's latest achievements." />
            </Helmet>
            <NavBar isSticky={isSticky} />
            <SubpageHero title="Blog Archive" _renderBreadcrumbs={(
                <div className="text-s2 sm:text-s3"><a href="/">Home</a> {">"} Blog</div>
            )} />
            <div className=" mx-auto flex-wrap justify-center items-start mt-10 max-w-full mb-10 px-[3%]">
                <div className={`${(blogListError.error || blogListSpinner) ? "opacity-10" : "opacity-100"} flex flex-row lg:justify-between justify-center flex-wrap col-span-full text-xs 2xl:max-w-[75vw] px-[5%]  mx-auto`}>
                        <div
                            onClick={() => changeCategory()}
                            // Check if all API-called categories are inactive, if so, set the "All posts" button to active
                            className={`${categories && categories.every(category => !category.isActive) ? filterBtnActiveClasses : filterBtnInactiveClasses} rounded-3xl py-2 text-center font-bold text-nowrap cursor-pointer px-5`}>
                            All posts
                        </div>
                        {categories && categories.map((category) => {
                            return (
                                <div
                                    key={category.id}
                                    onClick={() => changeCategory(category.id)}
                                    className={`${category.isActive ? filterBtnActiveClasses : filterBtnInactiveClasses} rounded-3xl py-2 text-center font-bold text-nowrap cursor-pointer px-5`}>
                                    {category.label}
                                </div>
                            )
                        })}

                    </div>
                <div className="grid grid-cols-[repeat(auto-fit,minmax(420px,max(420px,100%/4)))] gap-10 w-full mx-auto flex-wrap justify-center items-start mt-10 ">
                    {blogListSpinner && (<LoadingSpinner />)}
                    {
                        blogListError.error && <div className="text-3xl text-center col-span-full mx-auto text-nowrap mt-10">{blogListError.errorMessage}</div>
                    }
                    {
                        filteredBlogs && filteredBlogs.map((elem) => {
                            return (
                                <div key={elem.id} className="h-full">
                                    <InfoCardVertical
                                        img={elem.coverMedia.image.url}
                                        title={elem.title}
                                        body={elem.excerpt}
                                        btnText={"See more"}
                                        minutesToRead={elem.minutesToRead}
                                        btnLink={`/post/${elem.slug}`} />
                                </div>
                            )
                        })
                    }

                </div>
            </div>
            <Footer />
        </div>
    );
}

export default BlogList;