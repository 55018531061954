import InfoCardVertical from "components/InfoCardVertical";
import { links } from "constants/links";

const CTAFeature = () => {
    const data = [
        {
            img: require('assets/home/CTA_feature/1.png'),
            title: 'Simplifying trade has never been easier',
            body: 'Don\'t let complex processes and compliance demands slow down your business.Let Prima Trade\'s ‘Cash Against Data’ technology and expertise lead the way to a more efficient, ethical, and profitable future.',
            btnText: 'See more',
            btnLink: `${links.calendly}`
        },
        {
            img: require('assets/home/CTA_feature/2.png'),
            title: 'See the Difference',
            body: ' Learn more about our platform and how we make digital transformation seamless for businesses of all sizes.',
            btnText: 'See more',
            btnLink: `${links.calendly}`
        },
        {
            img: require('assets/home/CTA_feature/3.png'),
            title: 'Get Started Today',
            body: ' Contact us to discover how Prima Trade can transform your financial supply chain.',
            btnText: 'See more',
            btnLink: `${links.calendly}`
        }
    ]
    return (
        <div className="py-20 lg:px-28 flex flex-col min-[1400px]:flex-row gap-10 lg:gap-[10%] justify-center max-w-[1500px] mx-auto">
            <div className="flex flex-col gap-10 min-[1400px]:max-w-60 my-auto px-[10%] min-[1400px]:px-0 w-full text-center min-[1400px]:text-left mb-10 min-[1400px]:mb-auto">
                <div className="text-s3 font-bold">
                    Ready to Transform Your Financial Supply Chain?
                </div>
                <div>
                    Join the ranks of leading enterprises optimizing their financial supply chains with Prima Trade. Digitize, save, and comply — the future of trade finance is here.
                </div>
            </div>
            <div className="flex flex-row overflow-x-scroll no-scrollbar px-[10%] lg:px-0 pb-5 min-[1400px]:mx-auto min-[1400px]:w-fit lg:pr-1">
                <div className="h-fit my-auto lg:mr-10 mr-5">
                    <InfoCardVertical
                        {...data[0]} />
                </div>
                <div className="flex flex-row lg:flex-col gap-5 lg:gap-10">
                    <InfoCardVertical
                        {...data[1]} />
                    <InfoCardVertical
                        {...data[2]} />
                </div>
            </div>
        </div>
    );
}

export default CTAFeature;