import CTAButton from "components/CTAButton";
import { links } from "constants/links";
import { useEffect, useRef, useState } from "react";

const Hero = () => {
  const [videoPlayer, setVideoPlayer] = useState(false);
  const videoRef = useRef(null);

  const handleClickOutside = (event) => {
    if (videoRef.current && !videoRef.current.contains(event.target)) {
      setVideoPlayer(false);
    }
  };

  useEffect(() => {
    if (videoPlayer) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [videoPlayer]);
  return (
    <div className="sm:bg-[url('assets/home/pt-hero-bg.png')] bg-[url('assets/home/hero-bg-mobile.png')] relative w-full bg-cover h-[110vh] flex flex-col  top-0 gap-10">
      {videoPlayer && (
        <div className="h-screen w-screen top-0 z-50 bg-black flex justify-center items-center bg-opacity-40 fixed">
          <video
            ref={videoRef}
            controls
            src={require("assets/home/demo.mp4")}
            className="w-full max-w-[1000px] h-auto"
          ></video>
        </div>
      )}
      <div className="flex flex-col items-center justify-center gap-10 h-full sm:mt-[5%] sm:mb-[15%] px-[10%]">
        <div className="text-s4 lg:text-s5 text-white leading-[1.2] text-center lg:text-normal lg:leading-normal  h-fit font-primary font-bold">
          Cash <span className="text-primary-gradient">against </span> data
        </div>
        <div className="text-s1 lg:text-s2 text-white text-center">
          Enabling CFOs and Corporate Treasurers to strengthen their enterprise{" "}
          <br /> financial supply chains.
        </div>
        <div className="flex flex-col lg:flex-row gap-5 lg:gap-10 justify-center items-center">
          <CTAButton
            size={2}
            hasArrow={false}
            btnText={"Book a call"}
            href={links.calendly}
            opensNewTab={true}
          />
          <div
            className="flex flex-row gap-2 cursor-pointer hover:text-brand-orange hover:fill-brand-orange text-white  "
            onClick={() => setVideoPlayer(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <g clip-path="url(#clip0_175_24)">
                <path
                  d="M12.5001 0.225098C5.7251 0.225098 0.225098 5.7251 0.225098 12.5001C0.225098 19.2751 5.7251 24.7751 12.5001 24.7751C19.2751 24.7751 24.7751 19.2751 24.7751 12.5001C24.7751 5.7251 19.2751 0.225098 12.5001 0.225098ZM12.5001 22.7001C6.8751 22.7001 2.3001 18.1251 2.3001 12.5001C2.3001 6.8751 6.8751 2.3001 12.5001 2.3001C18.1251 2.3001 22.7001 6.8751 22.7001 12.5001C22.7001 18.1251 18.1251 22.7001 12.5001 22.7001Z"
                  fill="white"
                />
                <path
                  d="M10.3751 16.7252C10.5251 16.8002 10.675 16.8252 10.8251 16.8252C11.0001 16.8252 11.2001 16.7752 11.3501 16.6502L16.4001 13.2002C16.6501 13.0252 16.8001 12.7252 16.8001 12.4252C16.8001 12.1253 16.6501 11.8252 16.3751 11.6503L11.3251 8.30025C11.0252 8.10022 10.6751 8.10022 10.3501 8.25029C10.0501 8.42525 9.8501 8.72531 9.8501 9.07533V15.8753C9.87508 16.2503 10.05 16.5754 10.3751 16.7253V16.7252Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_175_24">
                  <rect width="25" height="25" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span className="text-s1 ">Play video</span>
          </div>
        </div>
      </div>
      <div className="hidden sm:block absolute bottom-0 left-[30%] h-auto w-[40vw]">
        <img
          src={require("assets/home/hero-2.png")}
          alt=""
          className="w-full h-full"
        />
      </div>
    </div>
  );
};

export default Hero;
