const AboutUs = () => {
    return (
        <div className="flex flex-col md:flex-row gap-20 px-[12%] mx-auto py-10 md:py-32">
            <div className="flex flex-col gap-10 max-w-[900px]">
                <div className="text-s4 font-bold leading-none">
                    About us
                </div>
                <div className="text-s2 leading-9">
                    We are a dynamic and experienced team with decades of banking, technology, accounts payable, finance, sustainability, and trade payable / trade finance experience.
                    <br />
                    <br />
                    We digitise trade to deliver "cash against data". Our mission is to change how suppliers are paid. Working with major international banking groups and some of the largest businesses in the world - we have designed our platform around our new product "cash against data" enabling a new form of AP automation and an amazing supply chain finance platform, built from the ground up.
                    <br />
                    <br />
                    With over $1bn in flows already processed across clients in 40 countries, our SCF platform is proven and scaling quickly - saving $ millions for corporate buyers whilst getting their suppliers paid at shipment, and going live without an IT project.
                </div>
            </div>
            <div className="h-full w-auto lg:min-w-[450px] mx-auto hidden lg:block">
                <img src={require('assets/platform/about-us.png')} alt="Factory worker" width="100%" height="100%" />
            </div>
        </div>
    );
}

export default AboutUs;