import PersonCard from "components/PersonCard";

const Team = () => {
    const data = [
        {
            img: require('assets/home/team/kris.png'),
            name: "Kris Van Broekhoven",
            title: "Chief Executive",
            _renderBodyText: (
                <div>
                    Kris has 25 years' experience in trade and supply chain and was formerly Global Head of Commodies at Citibank after leading Deutsche Bank's structured commodity finance business in Europe.
                    <br />
                    <br />
                    In 2021, Kris decided to leave banking and use his experience, connections and knowledge to work on the digitisation challenge in trade finance. Before joining PrimaTrade as CEO, he was CFO at Komgo.
                    <br />
                    <br />
                    Kris brings product knowledge, leadership, network, and presence to PrimaTrade, especially amongst the trade finance and banking communities, natural funding partners for our platform.

                </div>
            )
        },
        {
            img: require('assets/home/team/tim.png'),
            name: "Tim Nicolle",
            title: "Founder & Chief Product Officer",
            _renderBodyText: (
                <div>
                    Tim founded Demica and has held senior roles at Deutsche Bank and Unicredit in London and was a partner in PwC.
                    <br />
                    <br />
                    Tim brings detailed business knowledge, organizational skills and experience. He founded PrimaTrade after seeing how operational and data problems across supply chains lead to unequal treatment of suppliers. He realised that fixing the data problem could then drive a significant democratisation of the supports available to suppliers, levelling the playing field and helping to address the global SME financing gap in trade.
                    <br />
                    <br />
                    Tim is the lead creative force behind the company’s products.

                </div>
            )
        },
        {
            img: require('assets/home/team/nigel.png'),
            name: "Nigel Davies",
            title: "Chief Technology Officer",
            _renderBodyText: (
                <div>
                    Nigel has held senior roles with Demica followed by BCS and First Data.
                    <br />
                    <br />
                    Nigel has extensive experience in agile software development and building high performance data-driven platforms in banking and corporate environments.
                    <br />
                    <br />
                    Nigel leads our technology teams and drives the engineering work that ensures our multi-tenanted platform performs for clients. PrimaTrade uses modern and proven models for scalability including a micro-services architecture supported by distributed processing, clustering and containerisation to ensure that our users around the world get a first class experience.
                </div>
            )
        },
    ]
    return (
        <div className="flex flex-col bg-brand-lightRed py-32 px-[7%] lg:px-[10%] gap-10">
            <div className="text-s4 font-bold">
                Team
            </div>
            <div>
                We digitise trade by getting suppliers to self-digitise their paperwork. Our technology enables supply chains to be run more efficiently and more ethically - generating savings for buyers, democratising the treatment of suppliers and driving new levels of reporting for ESG and sustainability.
                <br />
                <br />
                Our senior management team has decades of experience across supply chain, sourcing, trade and the working capital - and a unique perspective on the future of supply chain management.
            </div>
            <div className="flex flex-col lg:flex-row gap-8">
                {
                    data.map((elem) => {
                        return (<PersonCard
                            {...elem}
                        />)
                    })
                }

            </div>
        </div>
    );
}

export default Team;