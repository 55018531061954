import InfoCard from "components/InfoCard";

const VideoFeature = () => {
    const data = [
        {
            imgPath: require('assets/home/info-card-1.png'),
            title: "Big savings",
            body: "Our clients are saving 1%+ on their sourcing via supplier discounts for automated, instant, funded payments at shipment."
        },
        {
            imgPath: require('assets/home/info-card-2.png'),
            title: "Award-winning",
            body: "Our award-winning platform is proven at scale. Last year 500 companies across 22 countries trusted us to digitize their shipments and manage their supplier finance."
        },
        {
            imgPath: require('assets/home/info-card-3.png'),
            title: "ESG and e-Invoicing",
            body: "Data drives value – for ESG, for e-invoicing, for efficiency. Supplier data is a powerful new data source with many uses across your enterprise financial supply chain."
        },
    ]
    return (
        <div className="flex flex-col gap-5 py-16 px-[7%] text-black justify-center items-center">
            <div className="text-[45px] font-semibold relative w-full items-center justify-center">
                <div className="lg:absolute mx-auto right-0 -top-8 w-[10vw] min-w-[100px] max-w-[200px]">
                    <img src={require('assets/home/trust-badge.png')} alt="trusted by 500 companies" />
                </div>
                <div className="w-fit text-center leading-[1.2] lg:leading-normal lg:text-start mx-auto">
                    Cash <span className="text-primary-gradient">against </span> data <span className="font-normal">is different</span>
                </div>
            </div>

            <div className="text-s1 text-center">
                Our platform works like nothing you’ve ever seen - it is supplier finance powered by supplier data.
            </div>

            <div className="flex flex-col lg:flex-row gap-10 justify-between w-full my-20 max-w-[1500px]">
                <div className="flex flex-col items-center justify-start gap-10 lg:w-[90%] max-w-[800px]">
                    {
                        data.map((card) => {
                            return (
                                <InfoCard
                                    imgPath={card.imgPath}
                                    title={card.title}
                                    body={card.body}
                                />
                            )
                        })
                    }

                </div>
                <div className="w-fit mx-auto justify-center items-center flex h-full my-auto cursor-pointer">
                    <video controls src={require("assets/home/demo.mp4")} className="h-full w-full lg:w-[40vw] max-w-[800px] "></video>
                </div>
            </div>
        </div>
    );
}

export default VideoFeature;