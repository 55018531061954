import CTAButton from "components/CTAButton";
import { links } from "constants/links";

const CTABanner = () => {
  return (
    <div className="w-[90%] mx-auto h-[680px] py-10">
      <div className="bg-[url('assets/home/cta-banner.png')] h-full w-full bg-cover rounded-[70px] bg-top flex justify-center items-center overflow-hidden px-8">
        <div className="flex flex-col items-center justify-center gap-10">
          <div className="text-7xl text-center sm:text-[100px] text-white font-primary font-bold">
            Get <span className="text-primary-gradient">started </span> today
          </div>
          <div className="sm:text-s2 text-white text-center">
            Contact us to discover how Prima Trade can transform <br /> your
            financial supply chain.
          </div>
          <div className="flex flex-col sm:flex-row gap-10 justify-center items-center">
            <CTAButton
              hasArrow={false}
              btnText={"Book a call"}
              href={links.calendly}
              opensNewTab={true}
              size={2}
            />
            <div className="flex flex-row gap-5">
              <a
                className="text-s1 text-white hover:text-brand-orange"
                href={links.calendly}
                target="_blank"
                rel="noreferrer"
              >
                Get in touch
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTABanner;
