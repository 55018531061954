import RouteList from "routes/RouteList";

function App() {

  return (
    <>
      <RouteList />
    </>
  );
}

export default App;
